import React, { useContext, useState } from "react"
import styled, { css } from "styled-components"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { underline } from "../styles/effects"

// Constants
import { paths } from "../constants/paths"
import AppContext from "../context/AppContext"
import zap from "../images/zap.svg"

// Components
import ContentWrapper from "./ContentWrapper"
import Button from "./atoms/Button"
import Icon from "./icons/Icon"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Types
import { ISlider, ISlide } from "../prismic-types/homepage"

interface IProps extends ISlider {
  className?: string
  rowTabs?: boolean
}

const SliderContainer: React.FC<IProps> = ({
  label,
  title,
  description,
  className,
  slides,
  rowTabs,
}) => {
  const context = useContext(AppContext)
  const { getLocalizedLink } = useLocale()
  const [selectedFeature, setSelectedFeature] = useState<ISlide>(slides[0])

  const current = slides.findIndex(
    (feature) => feature.label === selectedFeature.label
  )

  return (
    <Container id="product" className={className}>
      <StyledContentWrapper>
        {label && <TitleLabel>{label}</TitleLabel>}
        {title && (
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        {description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </StyledContentWrapper>
      <Content rowTabs={rowTabs}>
        <Tabs rowTabs={rowTabs}>
          {slides.map(
            (feature) =>
              feature.label && (
                <React.Fragment key={feature.label}>
                  <StyledTab
                    label={feature.label}
                    variant="ghost"
                    row={!rowTabs}
                    active={feature.label === selectedFeature.label}
                    onClick={() => setSelectedFeature(feature)}
                  />
                </React.Fragment>
              )
          )}
        </Tabs>
        <Feature rowTabs={rowTabs}>
          {selectedFeature.image && (
            <Img
              src={selectedFeature.image.url?.src}
              first
              alt={selectedFeature.image.alt || selectedFeature.label}
            />
          )}
          <TextContainer rowTabs={rowTabs}>
            <FeatureTitle>{selectedFeature.title}</FeatureTitle>
            <Text dangerouslySetInnerHTML={{ __html: selectedFeature.text }} />
            {selectedFeature.image && (
              <StyledButton
                variant="secondary"
                label="More about the platform"
                onClick={() => {
                  if (context) {
                    context.setScrollToAnchor(
                      selectedFeature.label?.toLowerCase() || ""
                    )
                  }
                }}
                href={getLocalizedLink(paths.platform)}
              />
            )}
          </TextContainer>
        </Feature>
        <MobileTabs>
          <Nav
            onClick={() => {
              current === 0
                ? setSelectedFeature(slides[slides.length - 1])
                : setSelectedFeature(slides[current - 1])
            }}
          >
            <Icon
              icon="chevron"
              direction="left"
              width={14}
              height={24}
              color={colors.purpleLight}
            />
          </Nav>
          {slides.map(
            (feature) =>
              feature.label && (
                <React.Fragment key={feature.label}>
                  <MobileTab
                    active={feature.label === selectedFeature.label}
                    onClick={() => setSelectedFeature(feature)}
                  />
                </React.Fragment>
              )
          )}
          <Nav
            onClick={() => {
              current === slides.length - 1
                ? setSelectedFeature(slides[0])
                : setSelectedFeature(slides[current + 1])
            }}
          >
            <Icon
              icon="chevron"
              direction="right"
              width={14}
              height={24}
              color={colors.purpleLight}
            />
          </Nav>
        </MobileTabs>
      </Content>
    </Container>
  )
}

const Container = styled.section`
  padding: 64px 0;
  background: ${colors.greyLight};

  ${mq.from.M`
    padding: 88px 0;
  `};

  ${mq.from.L`
    padding: 120px 0;
  `};
`

const Content = styled(ContentWrapper)<{ rowTabs?: boolean }>`
  ${mq.from.M`
    display: flex;
    flex-direction: ${({ rowTabs }: { rowTabs: boolean }) =>
      rowTabs ? "row" : "column"};
    justify-content: center;
    `}

  ${mq.from.M`
    ${({ rowTabs }: { rowTabs: boolean }) => rowTabs && "align-items: center;"};
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  position: relative;
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    > * {
      width: calc(10/12*100%);
      margin-left: auto;
      margin-right: auto;
    }
  `}
`

const TitleLabel = styled.label`
  ${textStyles.titleS};
  font-weight: 400;
  color: ${colors.text};
  margin: 0 auto 24px;
  text-align: center;

  ${mq.from.M`
  font-size: 20px !important;
  `}
`

const Title = styled.div`
  color: ${colors.purpleGradient};
  text-align: center;
  > h1,
  h3 {
    ${textStyles.titleXL};

    b,
    strong {
      ${underline};
    }
  }

  margin-bottom: 32px;

  ${mq.from.M`
    margin-bottom: 40px;
    > h3 {
      font-size: 48px !important;
    }
  
    > h1 {
      font-size: 48px !important;
    }
  `}
`

const Description = styled.div`
  ${textStyles.body}
  margin-bottom: 48px;
  text-align: center;

  p {
    margin: 0;
  }

  ${mq.from.M`
    margin: 0 auto 104px;
  `}
`

const Tabs = styled.div<{ rowTabs?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ rowTabs }) => (rowTabs ? "0 48px 0 0" : "0 auto 80px")};
  flex-direction: ${({ rowTabs }) => (rowTabs ? "column" : "row")};
  flex-shrink: 0;

  ${({ rowTabs }) =>
    rowTabs
      ? `border-right: 1px solid ${colors.black};`
      : `
      border-bottom: 1px solid ${colors.black};
      width: fit-content;
    `}

  ${mq.to.M`
    display: none;
  `}

  > button:not(:last-of-type) {
    margin: ${({ rowTabs }) => (rowTabs ? "0 0 24px" : "0 24px 0 0")};
  }
`

const MobileTabs = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${mq.to.M`
    display: flex;
    margin: 40px 0 0;

  > div {
    margin-right: 8px;
  }

  > div:nth-of-type(1) {
    margin-right: 24px;
  }

  > div:last-of-type {
    margin: 0 0 0 16px;
  }
`}
`

const Nav = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.purpleLightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`

const MobileTab = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? colors.purpleLight : colors.greyHighlight};

  ${mq.from.M`
    display: none;
  `}
`

const StyledTab = styled(Button)<{ active: boolean; row: boolean }>`
  padding: 16px 24px;
  font-size: 22px;
  display: block;
  box-shadow: none;

  ${({ row }) =>
    row
      ? `
    border-radius: 8px 8px 0 0;
    border-bottom: none;

    &:hover {
      border-bottom: none;
      border: 1px solid black;
      border-right: none;
    }

  `
      : `
      border-radius: 8px 0 0 8px;
      border-right: none;
      align-self: flex-end;

      &:hover {
        border: 1px solid black;
        border-right: none;
      }
      `}

  ${({ active }) =>
    !active
      ? `
    background: transparent;
    color: ${colors.text};
    font-weight: 400;
    border-color: transparent;
  `
      : `
    background: ${colors.white};
    font-weight: 700;
    border: 1px solid black;
    border-right: none;
  `};

  ${mq.to.M`
    display: none;
  `}
`

const Feature = styled.div<{ rowTabs?: boolean }>`
  display: flex;
  flex-direction: column;

  ${mq.from.S`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  ${mq.from.L`
    width: ${({ rowTabs }: { rowTabs: boolean }) =>
      rowTabs ? "50%" : "calc(10/12*100%)"};
    margin: ${({ rowTabs }: { rowTabs: boolean }) =>
      rowTabs ? "0" : "0 auto"};
  `}
`

const Img = styled.img<{ first: boolean }>`
  width: 240px;
  height: 240px;
  order: 0;

  ${mq.to.S`
    align-self: center;
  `}

  ${mq.from.M`
    width: 375px;
    height: 375px;
  `}

  ${({ first }) =>
    !first &&
    css`
      ${mq.from.S`
        order: 2;
      `}
    `}
`

const TextContainer = styled.div<{ rowTabs?: boolean }>`
  order: 1;

  ${mq.from.S`
    width: ${({ rowTabs }: { rowTabs: boolean }) => (rowTabs ? "100%" : "50%")};
  `}
`

const FeatureTitle = styled.h4`
  color: ${colors.main["300"]};
  ${textStyles.titleM};
  margin: 0 0 24px;

  ${mq.from.M`
    font-size: 40px !important;
    margin: 0 0 32px;
  `}
`

const Text = styled.div`
  ${textStyles.body}
  margin: 0 0 40px;

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

const StyledButton = styled(Button)`
  ${mq.to.S`
    margin: 0 auto;
  `}
`

export default SliderContainer
