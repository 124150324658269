import React, { useRef, createRef, useState } from "react"
import styled, { css } from "styled-components"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import { fastTransition } from "../styles/animations"
import Chevron from "./icons/Chevron"

// Types
import { IFAQ } from "../prismic-types/resources"

interface IProps {
  content: IFAQ[]
  color?: string
}

const FAQ: React.FC<IProps> = ({ content, color = colors.purpleDark }) => {
  const [activeItems, setActiveItems] = useState<number[]>([])

  const refs = useRef<any>([])

  refs.current = content.map(
    (_ref, index) => (refs.current[index] = createRef())
  )

  const handleOpenItem = (i: number) => {
    const element = refs.current[i].current
    if (activeItems.includes(i)) {
      setActiveItems((oldState) => {
        const newState = oldState.filter((item) => item !== i)
        return newState
      })
      element.style.height = "0px"
    } else {
      setActiveItems((oldState) => [...oldState, i])
      element.style.height = `${element.scrollHeight}px`
    }
  }

  return (
    <>
      {content.map((item, i) => (
        <Item
          key={`faq-${item.title}-${i}`}
          active={activeItems.includes(i)}
          color={color}
        >
          <Title
            onClick={() => handleOpenItem(i)}
            active={activeItems.includes(i)}
            color={color}
          >
            {item.title}
            <Chevron
              direction={activeItems.includes(i) ? "up" : "down"}
              width={24}
              height={14}
              color={
                color ||
                (activeItems.includes(i) ? colors.purpleLight : colors.text)
              }
            />
          </Title>
          <Text
            dangerouslySetInnerHTML={{ __html: item.text }}
            ref={refs.current[i]}
            active={activeItems.includes(i)}
          />
        </Item>
      ))}
    </>
  )
}

const Item = styled.div<{ active: boolean; color: string }>`
  overflow: hidden;
  margin-bottom: 32px;
  width: 100%;
  border-bottom: 1px solid ${colors.purpleLighter};
  color: ${({ color }) => color};

  ${({ active }) =>
    active &&
    `
    padding-bottom: 24px;
  `}
`

const Title = styled.h5<{ active: boolean; color: string }>`
  color: ${({ color }) => color};
  ${textStyles.titleS}
  font-weight: 500 !important;
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  padding-right: 4px;
  padding-bottom: 16px;
  transition: padding ${fastTransition};
  display: flex;
  align-items: center;

  > svg {
    margin-left: auto;
  }

  ${mq.from.S`
    margin-bottom: 0;
    padding-bottom: 0;
  `}

  ${mq.from.M`
  font-size: 22px !important;
`}

  ${({ active }) =>
    active &&
    css`
      padding-bottom: 16px;

      ${mq.from.S`
      padding-bottom: 24px;
    `}
    `}
`

const Text = styled.div`
  color: ${({ color }) => color};
  ${textStyles.body}
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity ${fastTransition}, height ${fastTransition};
  ${({ active }: { active: boolean }) =>
    active &&
    `
    opacity: 1;
    pointer-events: initial;
  `}
`

export default FAQ
