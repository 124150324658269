import React, { useState } from "react"
import styled from "styled-components"
import { scroller, Element } from "react-scroll"

// Components
import ContentWrapper from "./ContentWrapper"
import Icon from "./icons/Icon"
import Button from "./atoms/Button"

// Constants
import { paths } from "../constants/paths"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Types
import { ITestimonials, ITestimonial } from "../prismic-types/homepage"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

interface IProps {
  testimonials: ITestimonials
  version?: "white" | "black"
}

const TestimonialSlider: React.FC<IProps> = ({ testimonials, version }) => {
  const { getLocalizedLink } = useLocale()
  const [scrolledToEnd, setScrolledToEnd] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<"" | "left" | "right">("")
  const scroll = () => {
    scroller.scrollTo(!scrolledToEnd ? "last" : "first", {
      horizontal: true,
      duration: 800,
      spy: true,
      smooth: true,
      delay: 50,
      containerId: "scrollContainer",
    })
    setScrolledToEnd(!scrolledToEnd)
  }

  return (
    <Section white={version === "white"}>
      <ContentWrapper>
        {testimonials.title && (
          <Title
            white={version === "white"}
            dangerouslySetInnerHTML={{
              __html: testimonials.title,
            }}
          />
        )}
        {testimonials.description && (
          <Text
            white={version === "white"}
            dangerouslySetInnerHTML={{
              __html: testimonials.description,
            }}
          />
        )}

        {testimonials.testimonials && (
          <>
            <ScrollContainer id="scrollContainer">
              {testimonials.testimonials.map(
                (card: ITestimonial, index: number) => (
                  <CompanyCard
                    key={`company-card-${index}`}
                    name={
                      index === 0
                        ? "first"
                        : index === testimonials.testimonials.length - 1
                        ? "last"
                        : index.toString()
                    }
                  >
                    {card.logo && card.logo.url && (
                      <Logo
                        src={card.logo.url.src}
                        role="image"
                        aria-label={card.logo.alt}
                      />
                    )}
                    <TextContainer>
                      <CompanyName>
                        <StyledIcon
                          icon="company"
                          color={colors.whitePurple}
                          width={26}
                          height={24}
                        />
                        {card.client.name}
                      </CompanyName>
                      <CompanyType>{card.client.position}</CompanyType>
                      <CompanySize>
                        <StyledIcon
                          icon="user"
                          width={20}
                          height={20}
                          color={colors.whitePurple}
                        />
                        {card.client.size}
                      </CompanySize>
                    </TextContainer>
                  </CompanyCard>
                )
              )}
            </ScrollContainer>
            <Nav>
              <NavButton
                onMouseEnter={() => setIsHovered("left")}
                onMouseLeave={() => setIsHovered("")}
                disabled={!scrolledToEnd}
                onClick={() => (scrolledToEnd ? scroll() : undefined)}
              >
                <Icon
                  icon="arrowRight"
                  direction="left"
                  color={
                    isHovered === "left" ? colors.white : colors.purpleLight
                  }
                  width={24}
                  height={24}
                />
              </NavButton>
              <NavButton
                onMouseEnter={() => setIsHovered("right")}
                onMouseLeave={() => setIsHovered("")}
                disabled={scrolledToEnd}
                onClick={() => (!scrolledToEnd ? scroll() : undefined)}
              >
                <Icon
                  icon="arrowRight"
                  direction="right"
                  color={
                    isHovered === "right" ? colors.white : colors.purpleLight
                  }
                  width={24}
                  height={24}
                />
              </NavButton>
              <StyledButton
                fullWidth={false}
                position="right"
                variant="tertiary"
                label="Get in touch"
                href={getLocalizedLink(paths.demo)}
              />
            </Nav>
          </>
        )}
      </ContentWrapper>
    </Section>
  )
}

export default TestimonialSlider

const Section = styled.section<{ white?: boolean }>`
  padding: 96px 0;
  overflow: hidden;
  background: ${({ white }) => (white ? colors.purple : colors.yellowLight)};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const StyledButton = styled(Button)`
  margin-left: auto;
`

const Title = styled.div<{ white?: boolean }>`
  color: ${({ white }) => (white ? colors.white : colors.text)};
  ${textStyles.titleL}
  margin: 0 0 8px;

  > h3 {
    color: ${({ white }) => (white ? colors.white : colors.text)};
    ${textStyles.titleL}
    margin: 0 0 8px;
  }
`

const Text = styled.div<{ white?: boolean }>`
  > p {
    color: ${({ white }) => (white ? colors.white : colors.text)};
    ${textStyles.body}
    margin: 0 0 24px;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
  > p {
   font-size: 20px;
  }
  `}
`

const CompanySize = styled.div`
  color: ${colors.main["200"]};
  display: flex;
  align-items: center;
  ${textStyles.body};
  margin-bottom: 0;
`

const CompanyName = styled.div`
  color: ${colors.main["200"]};
  display: flex;
  align-items: center;
  text-align: center;
  ${textStyles.subtitle};
`

const CompanyType = styled.p`
  color: ${colors.main["300"]};
  ${textStyles.body};
  margin-bottom: 16px;
`

const ScrollContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  margin: 24px 0;

  /* Hide horizontal scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    height: 0;
  }

  /* Hide horizontal scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  ${mq.from.M`
    margin: 64px 0 48px;
    width: calc(50% + 50vw);
    padding-right: 24px;
  `}
`

const NavButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.whiteGrey};
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(42, 9, 134, 0.1);

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: ${colors.whiteGrey};
    }
  }

  ${mq.from.M`
    width: 52px;
    height: 52px;
  `}

  &:first-of-type {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${colors.purpleLight};
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const TextContainer = styled.div`
  background-color: ${colors.greyLight};
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Logo = styled.div<{ src?: string }>`
  height: 96px;
  padding: 24px;
  background-image: url("${({ src }) => src}");
  box-sizing: content-box;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;

  ${mq.from.L`
    height: 140px;
  `}
`

const CompanyCard = styled(Element)`
  min-width: 240px;
  width: 100%;
  background-color: ${colors.white};
  margin-right: 24px;
  border-radius: 12px;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: end;
  grid-template-columns: 1fr;

  &:last-of-type {
    margin-right: 0;
  }

  ${mq.from.M`
    min-width: 340px;
  `}
`
