import React from "react"
import styled from "styled-components"
import GatsbyImage, { GatsbyImageProps } from "gatsby-image"

// Components
import zap from "../images/zap.svg"
import FAQ from "./FAQ"
import Button from "./atoms/Button"

// Styling
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Types
import { IHowWeDoListItem } from "../prismic-types/method"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

interface IProps extends IHowWeDoListItem {
  mirrored?: boolean
}

const StepsListItem: React.FCS<IProps> = ({
  title,
  subtitle,
  text,
  image,
  mirrored,
  items,
  buttons,
  className,
}) => {
  const { getLocalizedLink } = useLocale()

  return (
    <Li className={className} mirrored={mirrored}>
      <TextWrapper>
        {subtitle && (
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
        {items && (
          <ItemsWrapper>
            <FAQ content={items} />
          </ItemsWrapper>
        )}
        {buttons && (
          <ButtonRow>
            {buttons.map((button, index) => (
              <Button
                key={`button-${index}`}
                label={button.label}
                variant={index === 0 ? "primary" : "tertiary"}
                href={getLocalizedLink(button.url)}
                fullWidth={false}
              />
            ))}
          </ButtonRow>
        )}
      </TextWrapper>
      {image?.fluid && (
        <StyledGatsbyImage fluid={image.fluid} alt={image.alt ?? ""} />
      )}
    </Li>
  )
}

const Li = styled.li<{ mirrored?: boolean }>`
  ${mq.from.M`
    display: flex;
    ${({ mirrored }: { mirrored?: boolean }) =>
      mirrored && "flex-direction: row-reverse;"}
    align-items: center;
    justify-content: space-between;
  `}
`

const TextWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  border-left: 1px solid ${colors.main["300"]};

  ${mq.from.M`
    width: 50%;
    margin-bottom: 0;
  `}
`

const Title = styled.div`
  > h1,
  h3 {
    color: ${colors.main["100"]};
    ${textStyles.titleL}
    margin: 0 0 24px;
  }
`

const Subtitle = styled.div`
  > h4 {
    color: ${colors.pink};
    ${textStyles.titleS};
    margin-bottom: 4px;
  }
`

const Text = styled.div`
  > p {
    ${textStyles.body}
    margin: 0 0 24px;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

const ItemsWrapper = styled.div`
  margin-top: 40px;
`

const StyledGatsbyImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${mq.from.M`
    flex-shrink: 0;
    width: calc(5/12*100%);
  `}
`

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 32px;

  > button:nth-of-type(2) {
    margin-left: 16px;
  }
`

export default StepsListItem
