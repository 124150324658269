import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "./ContentWrapper"
import ListItem from "./StepsListItem"

// Types
import { IHowWeDo } from "../prismic-types/method"

interface IProps extends IHowWeDo {
  className?: string
  mirrored?: boolean
}

const StepsList: React.FC<IProps> = ({ className, list, mirrored }) => (
  <Section className={className}>
    <ContentWrapper>
      {list.length > 0 && (
        <Ol>
          {list.map((listItem, index) => (
            <StyledListItem
              key={`list-item-${index}`}
              {...listItem}
              mirrored={mirrored || index % 2 !== 0}
            />
          ))}
        </Ol>
      )}
    </ContentWrapper>
  </Section>
)

const Section = styled.section`
  padding: 16px 0 96px;
  background: linear-gradient(
    180deg,
    ${colors.greyLight} 0%,
    ${colors.blueSoft} 100%
  );

  ${mq.from.L`
    padding: 40px 0 192px;
  `}
`

const Ol = styled.ol`
  margin: 0;
  list-style-type: none;
`

const StyledListItem = styled(ListItem)`
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.from.S`
    margin-bottom: 96px;
  `}

  ${mq.from.L`
    margin-bottom: 128px;
  `}
`

export default StepsList
