import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Components
import Seo from "../../components/Seo"
import Intro from "../../components/Intro"
import Explanation from "../../components/Explanation"
import SliderContainer from "../../components/SliderContainer"
import StepsList from "../../components/StepsList"
import ContentWrapper from "../../components/ContentWrapper"
import FAQ from "../../components/FAQ"
import TestimonialSlider from "../../components/TestimonialSlider"

// Custom Hooks
import { useLocale } from "../../hooks/useLocale"

// Constants
import { getUrl, paths } from "../../constants/paths"

// Styles
import colors from "../../styles/colors"
import mq from "../../styles/breakpoints"
import textStyles from "../../styles/textStyles"

// Types
import { IHowWeDo, IHowWeDoListItem } from "../../prismic-types/method"

interface IProps {
  content: any
}

const EvaluationPage: React.FC<IProps> = ({ content }) => {
  const { getLocalizedLink } = useLocale()
  const slug = "platform/evaluation"

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug={slug}
        imageUrl={content.intro.desktopImg.imageUrl}
        breadCrumbItems={[
          { name: "Platform", url: "platform" },
          { name: "Evaluation", url: slug },
        ]}
      />
      <Intro
        {...content.intro}
        buttonVariant="primary"
        buttonLink={getLocalizedLink(paths.demo)}
        buttonColorScheme="white"
        variant="home"
        color="purple"
        shapeColor={colors.backgroundPurple}
      />
      <StyledExplanation
        pageGroup="HomeAndPlatform"
        {...content.explanation}
        smallImages
      />
      <StyledTextImage {...content.howWeDo} mirrored />
      {content.inDepth && (
        <Section>
          <StyledContentWrapper>
            <TextWrapper>
              {content.inDepth.title && <Title>{content.inDepth.title}</Title>}
              {content.inDepth.text && (
                <Text
                  dangerouslySetInnerHTML={{ __html: content.inDepth.text }}
                />
              )}
            </TextWrapper>
            <ItemsWrapper>
              <FAQ content={content.inDepth.items} color={colors.white} />
            </ItemsWrapper>
          </StyledContentWrapper>
        </Section>
      )}
      <StyledSliderContainer {...content.features} rowTabs />
      <TestimonialSlider testimonials={content.testimonials} version="white" />
      <TextImageBlock
        title={content.cta.ctaTitle}
        text={content.cta.ctaText}
        titleTag="h3"
        desktopImg={content.cta.ctaImage}
        buttonLabel={content.cta.ctaButtonLabel}
        buttonLink={getLocalizedLink(getUrl(content.cta.ctaLink))}
        variant="detail"
        textColor="light"
        buttonVariant="tertiary"
        shapeColor=""
      />
    </>
  )
}

const StyledSliderContainer = styled(SliderContainer)`
  background: ${colors.backgroundPurple};
  label {
    color: ${colors.pink};
  }
`

const StyledExplanation = styled(Explanation)`
  background: ${colors.backgroundPurple};
`

const TextImageBlock = styled(Intro)`
  margin-bottom: 0;
  background: linear-gradient(
    180.06deg,
    ${colors.purpleLight} 22.37%,
    ${colors.purpleDarkest} 99.95%
  );

  ${mq.from.M`
    padding-bottom: 160px;
  `}
`

const Section = styled.section`
  padding: 96px 0;
  background: ${colors.purpleLight};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(10/12*100%);
  `}
`

const TextWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    width: calc(4/10*100%);
    margin-bottom: 0;
  `}
`

const Title = styled.h3`
  color: ${colors.white};
  ${textStyles.titleL}

  margin: 0 0 24px;
`

const Text = styled.div<{ white?: boolean }>`
  > p {
    color: ${colors.white};
    ${textStyles.body}
    margin: 0 0 24px;
  }

  > p:last-child {
    margin-bottom: 0;
  }
`

const ItemsWrapper = styled.div`
  ${mq.from.M`
    width: calc(4/10*100%);
  `}

  > div {
    margin-bottom: 12px;
    border-color: ${colors.white};

    > div {
      margin-bottom: 12px;
    }
  }
`

const StyledTextImage = styled(StepsList)`
  background: ${colors.backgroundPurple};
  padding: 96px 0;

  ${mq.from.L`
    padding: 120px 0;
  `}
`

export const query = graphql`
  query evaluationPage($locale: String!) {
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          last_publication_date
          first_publication_date
          lang
          data {
            # Meta
            meta_title
            meta_description

            # Intro
            intro_title {
              text
            }
            intro_text {
              html
            }
            intro_image_desktop {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
              url
            }
            intro_button_label

            # Explanation
            explanation_title {
              html
            }
            explanation_text {
              html
            }
            explanations {
              title {
                text
              }
              description {
                html
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }

            # How we do it
            how_we_do_list {
              title {
                html
              }
              subtitle {
                html
              }
              text {
                html
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }

            # In depth
            depth_title {
              text
            }
            depth_text {
              html
            }
            depth_list {
              text {
                text
              }
              title {
                text
              }
            }

            # Features
            features_label {
              text
            }
            features_title {
              html
            }
            features_description {
              html
            }
            features {
              label
              title {
                text
              }
              text {
                html
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }

            # Testimonials
            testimonials_title {
              html
            }
            testimonials_description {
              html
            }
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              client_name
              client_position
              client_size
            }

            # CTA
            cta_title {
              text
            }
            cta_text {
              html
            }
            cta_button_link
            cta_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            cta_button_label
          }
        }
      }
    }
    allPrismicFeaturesPage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            # Shape block
            shape_block_title {
              text
            }
            shape_block_text {
              html
            }
            shape_block_label
            shape_block_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            shape_block_prices {
              price
              label
              caption
              button_link
              button_label
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    last_publication_date,
    first_publication_date,
    lang,
  } = data.allPrismicEvaluationDetail.edges[0].node
  const platformData = data.allPrismicFeaturesPage.edges[0].node.data

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const intro = {
    title: raw.intro_title.text,
    text: raw.intro_text.html,
    desktopImg: {
      alt: raw.intro_image_desktop.alt,
      url: raw.intro_image_desktop.fluid,
      imageUrl: raw.intro_image_desktop.url,
    },
    buttonLabel: raw.intro_button_label,
  }

  const shape = {
    label: platformData.shape_block_label,
    title: platformData.shape_block_title.text,
    text: platformData.shape_block_text.html,
    image: {
      alt: platformData.shape_block_image.alt,
      url: platformData.shape_block_image.fluid,
    },
    prices: platformData.shape_block_prices.map((price: any, i: number) => ({
      price: price.price,
      label: price.label,
      caption: price.caption,
      button: {
        link: price.button_link,
        label: price.button_label,
        variant: i === 0 ? "primary" : undefined,
      },
    })),
  }

  const explanation = {
    title: raw.explanation_title?.html,
    text: raw.explanation_text?.html,
    explanations: raw.explanations.map((exp: any) => ({
      title: exp.title.text,
      label: exp.label,
      description: exp.description.html,
      image: {
        alt: exp.image?.alt,
        url: exp.image?.fluid,
      },
    })),
  }

  const howWeDoList: IHowWeDoListItem[] = raw.how_we_do_list.map(
    (item: any) => ({
      title: item.title.html,
      subtitle: item.subtitle.html,
      text: item.text.html,
      image: item.image,
      buttons: [
        { label: `Your manifesto`, url: paths.manifesto },
        {
          label: "Our other features",
          url: paths.platform,
          variant: "primary",
        },
      ],
    })
  )
  const howWeDo: IHowWeDo = {
    list: howWeDoList,
  }

  const inDepth = {
    title: raw.depth_title?.text,
    text: raw.depth_text?.html,
    items: raw.depth_list?.map((item: any) => ({
      title: item?.title?.text,
      text: item?.text?.text,
    })),
  }

  const formattedFeatures = raw.features.map((slide: any) => ({
    title: slide.title.text,
    label: slide.label,
    text: slide.text.html,
  }))

  const features = {
    title: raw.features_title.html,
    label: raw.features_label?.text,
    description: raw.features_description?.html,
    slides: formattedFeatures,
  }

  const formattedTestimonials = [] as any
  raw.testimonials.forEach((testimonial: any) => {
    formattedTestimonials.push({
      logo: {
        alt: testimonial.logo?.alt,
        url: testimonial.logo?.fluid,
      },
      client: {
        name: testimonial.client_name,
        position: testimonial.client_position,
        size: testimonial.client_size,
      },
    })
  })

  const testimonials = {
    title: raw.testimonials_title.html,
    description: raw.testimonials_description.html,
    testimonials: formattedTestimonials,
  }

  const cta = {
    ctaTitle: raw.cta_title.text,
    ctaText: raw.cta_text.html,
    ctaLink: raw.cta_button_link,
    ctaImage: {
      alt: raw.cta_image.alt,
      url: raw.cta_image.fluid,
    },
    ctaButtonLabel: raw.cta_button_label,
  }

  const content = {
    meta,
    intro,
    explanation,
    howWeDo,
    inDepth,
    features,
    testimonials,
    shape,
    cta,
  }

  return <EvaluationPage content={content} />
}
