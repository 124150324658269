import { css } from "styled-components"

// Styles
import mq from "./breakpoints"

// Components
import line from "../images/underline.svg"
import nameScribble from "../images/name-scribble.svg"

export const underline = css`
  position: relative;
  z-index: 1;

  ${mq.from.M`
    &:after {
      position: absolute;
      content: "";
      background-image: url("${line}");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      bottom: 0px;
      left: -4px;
      width: calc(100% + 6px);
      height: 16px;
    }
  `}
`

export const scribble = css`
  position: relative;
  z-index: 1;

  ${mq.from.M`
    &:after {
      position: absolute;
      content: "";
      background-image: url("${nameScribble}");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      bottom: 100%;
      left: 50%;
      width: 176px;
      height: 68px;
    }
  `}
`
